import { ChangeDetectionStrategy, Component, inject, signal } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { ErrorService, getCurrentTimeWithinMinute, greaterThanInstant, Instant, MyMatDateTimePickerComponent, SpinnerButtonComponent } from "common";
import { EndPilotageParams, PilotageDetails, PilotageEndpoint, PilotageId } from "apina-frontend";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { TranslateModule } from "@ngx-translate/core";
import { VerticalFormComponent } from "../../forms/vertical-form/vertical-form.component";
import { InputRowComponent } from "../../forms/input-row/input-row.component";

@Component({
    templateUrl: './end-pilotage.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatButtonModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatFormFieldModule,
        MatIconModule,
        ReactiveFormsModule,
        SpinnerButtonComponent,
        TranslateModule,
        MyMatDateTimePickerComponent,
        VerticalFormComponent,
        InputRowComponent,
    ]
})
export class EndPilotageComponent {

    private readonly params = inject<EndPilotageComponentParams>(MAT_DIALOG_DATA);
    private readonly dialogRef = inject(MatDialogRef<EndPilotageComponent>);
    private readonly errorService = inject(ErrorService);
    private readonly pilotageEndpoint = inject(PilotageEndpoint);

    readonly viewData = signal<EndPilotageViewData | undefined>(undefined);
    readonly progress = signal(false);

    constructor() {
        this.pilotageEndpoint.getPilotageDetails(this.params.pilotageId).then(pilotage => {
            const form = new FormGroup<EndPilotageViewForm>({
                startTime: new FormControl<Instant>(pilotage.startTime, {validators: Validators.required, nonNullable: true}),
                endTime: new FormControl<Instant>(getCurrentTimeWithinMinute(), {validators: Validators.required, nonNullable: true})
            });

            form.controls.endTime.addValidators(greaterThanInstant(form.controls.startTime));
            form.controls.startTime.valueChanges.subscribe(() => form.controls.endTime.updateValueAndValidity());

            this.viewData.set({pilotage, form});
        });
    }

    async endPilotage(form: FormGroup<EndPilotageViewForm>): Promise<void> {
        const params = form.value as EndPilotageParams;
        this.progress.set(true);

        try {
            await this.pilotageEndpoint.endPilotage(this.params.pilotageId, params);
            this.dialogRef.close(true);
        } catch (e) {
            this.progress.set(false);
            return this.errorService.showUpdateError(e);
        }
    }
}

interface EndPilotageViewForm {
    startTime: FormControl<Instant>;
    endTime: FormControl<Instant>;
}

interface EndPilotageViewData {
    pilotage: PilotageDetails;
    form: FormGroup<EndPilotageViewForm>;
}

export interface EndPilotageComponentParams {
    pilotageId: PilotageId;
}

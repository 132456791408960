import { ChangeDetectionStrategy, Component, Inject, signal } from "@angular/core";
import { PilotageDetails, PilotageEndpoint, PilotageId, VesselId } from "apina-frontend";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { CommonDialogFormComponent, CommonDialogFormDelegate } from "../../common/common-dialog-form/common-dialog-form.component";
import { SelectVesselComponent } from "../../common/select-vessel/select-vessel.component";
import { createDraftControls, EditDraftFieldsComponent } from "../../common/edit-draft-fields/edit-draft-fields.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { VerticalFormComponent } from "../../forms/vertical-form/vertical-form.component";
import { VesselsService } from "../../vessels/vessels.service";

@Component({
    templateUrl: "./change-vessel.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonDialogFormComponent,
        SelectVesselComponent,
        EditDraftFieldsComponent,
        MatFormFieldModule,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        VerticalFormComponent,

    ]
})
export class ChangeVesselComponent implements CommonDialogFormDelegate {

    readonly form = new FormGroup({
        vesselId: new FormControl<VesselId | null>(null, Validators.required),
        vessel2Id: new FormControl<VesselId | null>(null),
        ...createDraftControls(),
    });

    readonly pilotage = signal<PilotageDetails | undefined>(undefined);

    constructor(
        @Inject(MAT_DIALOG_DATA) private readonly params: ChangeVesselComponentParams,
        private readonly vesselsService: VesselsService,
        private readonly pilotageEndpoint: PilotageEndpoint,
    ) {

        pilotageEndpoint.getPilotageDetails(params.pilotageId).then(pilotage => {
            this.form.reset({
                vesselId: pilotage.vessel.id,
                vessel2Id: pilotage.vessel2?.id ?? null,
                draft: pilotage.drafts.max,
                draftFore: pilotage.drafts.fore,
                draftAft: pilotage.drafts.aft,
                airDraft: pilotage.drafts.air,
            });

            this.pilotage.set(pilotage);
        });
    }

    createNewVessel(): void {
        this.vesselsService.openNewVesselDialog();
    }

    openVesselInfoDialog(e: Event, vesselId: VesselId): void {
        e.preventDefault();
        this.vesselsService.openVesselDialog(vesselId);
    }

    isPristine(): boolean {
        return this.form.controls.vesselId.pristine && this.form.controls.vessel2Id.pristine;
    }

    doSave(): Promise<void> {
        return this.pilotageEndpoint.updateVessel(this.params.pilotageId, {
            vesselId: this.form.controls.vesselId.value!,
            vessel2Id: this.form.controls.vessel2Id.value,
            drafts: {
                max: this.form.controls.draft.value,
                fore: this.form.controls.draftAft.value,
                aft: this.form.controls.draftAft.value,
                air: this.form.controls.airDraft.value,
            }
        });
    }
}

export interface ChangeVesselComponentParams {
    pilotageId: PilotageId;
}

import { ChangeDetectionStrategy, Component, computed, inject, resource, Signal } from "@angular/core";
import { FullRouteInfo, RouteEndpoint, RouteId } from "apina-frontend";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { asRequired, controlValuesSignal, MinutesDurationPipe } from "common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MAT_DIALOG_DATA, MatDialogContent, MatDialogRef, MatDialogTitle } from "@angular/material/dialog";

@Component({
    templateUrl: './select-route-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MinutesDurationPipe,
        MatFormFieldModule,
        MatInputModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        MatDialogContent,
        MatDialogTitle,
    ],
    host: {
        "class": "!block max-h-[500px] max-w-[800px]"
    }
})
export class SelectRouteDialog {

    private readonly params = inject<SelectRouteParams>(MAT_DIALOG_DATA);
    private readonly routeEndpoint = inject(RouteEndpoint);
    private readonly dialogRef = inject(MatDialogRef<SelectRouteDialog>);

    readonly form = new FormGroup({
        startCode: new FormControl(this.params.start, {nonNullable: true}),
        endCode: new FormControl("", {nonNullable: true}),
    });

    readonly searchResults: Signal<FullRouteInfo[] | undefined>;

    constructor() {
        this.form.controls.startCode.reset(this.params.start);

        const allRoutes = resource({ loader: () => this.routeEndpoint.getFullRoutes()});
        const formSignal = controlValuesSignal(this.form);

        this.searchResults = computed(() => {
            const criteria = asRequired(formSignal());
            const routes = allRoutes.value();
            const startLower = criteria.startCode.toLowerCase().trim();
            const endLower = criteria.endCode.toLowerCase().trim();

            return routes?.filter(it => !it.removed && it.start.code.toLowerCase().startsWith(startLower) && it.end.code.toLowerCase().startsWith(endLower));
        });
    }

    selectRoute(route: FullRouteInfo): void {
        this.dialogRef.close();
        this.params.routeSelected(route.id, route.start.code, route.end.code);
    }
}

export interface SelectRouteParams {
    start: string;
    routeSelected: RouteSelectedCallback;
}

export type RouteSelectedCallback = (id: RouteId, startCode: string, endCode: string) => void;

@if (viewData(); as viewData) {
    <div class="mat-typography">
        <h3 mat-dialog-title>{{ viewData.pilotage.descriptionWithTime }}</h3>

        <mat-dialog-content>
            @if (viewData.alerts.length === 0) {
                <p>Ei hälytyksiä.</p>
            } @else {
                <table class="default-table flex flex-col gap-y-2 table-fixed">
                    <tbody>
                        @for (alert of viewData.alerts; track $index) {
                            <tr [class.line-through]="alert.completed.value">
                                @if (viewData.editedAlertId !== alert.id) {
                                    <td class="w-[40px] cursor-pointer" (click)="toggleCompletion(alert)">
                                        <mat-checkbox [formControl]="alert.completed" (click)="$event.stopPropagation()"/>
                                    </td>
                                    <td class="w-[120px] whitespace-nowrap cursor-pointer" (click)="toggleCompletion(alert)">
                                        <time>{{ alert.time | helsinkiDate:'HH:mm' }}</time>
                                        <span class="text-de-emphasized text-xs"> ({{ alert.alarmTimeDifference | duration }})</span>
                                    <td class="w-[500px] cursor-pointer" (click)="toggleCompletion(alert)">
                                        {{ alert.title }}
                                    </td>
                                    <td class="actions-cell w-[60px]">
                                        <button [disabled]="viewData.inEditMode" mat-icon-button matTooltip="Muokkaa" (click)="startEditing(alert)">
                                            <mat-icon>create</mat-icon>
                                        </button>
                                    </td>
                                } @else {
                                    <td></td>
                                    <td class="w-[120px] whitespace-nowrap">
                                        <mat-form-field class="w-[110px]">
                                            <mat-select [formControl]="alert.alarmTimeDifferenceControl">
                                                <mat-select-trigger>{{ formatMinutes(alert.alarmTimeDifferenceControl.value) }}</mat-select-trigger>
                                                @for (d of viewData.selectableAlertDeltas; track $index) {
                                                    <mat-option [value]="d.minutes" class="h-[60px]">
                                                        <div class="h-[20px] leading-[20px]">{{ d.duration | duration }}</div>
                                                        <div class="h-[20px] leading-[20px]">
                                                            <time class="text-de-emphasized text-xs">{{ d.time | helsinkiDate:'HH:mm' }}</time>
                                                        </div>
                                                    </mat-option>
                                                }
                                            </mat-select>
                                        </mat-form-field>
                                    </td>
                                    <td class="w-[500px]">
                                        <mat-form-field class="w-full">
                                            <textarea matInput [cdkTextareaAutosize]="true" [formControl]="alert.titleControl" cdkFocusInitial></textarea>
                                        </mat-form-field>
                                    </td>
                                    <td class="actions-cell w-[60px]">
                                        <button mat-icon-button matTooltip="Tallenna" (click)="save(alert, viewData.pilotage.id)" [disabled]="alert.titleControl.invalid">
                                            <mat-icon>save</mat-icon>
                                        </button>
                                        <button mat-icon-button matTooltip="Peruuta" (click)="cancelEditing()">
                                            <mat-icon>cancel</mat-icon>
                                        </button>
                                    </td>
                                }
                            </tr>
                        }
                    </tbody>
                </table>
            }
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-button (click)="addAlert(viewData.pilotage)" [disabled]="viewData.inEditMode">Lisää hälytys</button>
            <button mat-button mat-dialog-close>Sulje</button>
        </mat-dialog-actions>
    </div>
} @else {
    <mat-spinner class="default-spinner"/>
}

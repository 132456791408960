@if (pilotage(); as pilotage) {
    <app-common-dialog-form
        class="w-[800px]"
        [delegate]="this"
        [dialogTitle]="pilotage.descriptionWithTime"
        saveText="Vaihda alus">

        <app-vertical-form>
            <div class="grid grid-cols-7 gap-2">
                <mat-form-field class="col-span-3">
                    <mat-label>Alus</mat-label>
                    <app-select-vessel #vesselField [formControl]="form.controls.vesselId" tabindex="0" cdkFocusInitial [allowNewVessel]="true" (createNewVessel)="createNewVessel()" required/>
                    @if (form.controls.vesselId.value; as vesselId) {
                        <mat-hint align="end"><a href="#" (click)="openVesselInfoDialog($event, vesselId)" tabindex="-1">Aluksen tiedot</a></mat-hint>
                    }

                </mat-form-field>
                <app-edit-draft-fields class="col-span-4" [controls]="form.controls" [vesselId]="form.controls.vesselId.value"/>
            </div>
            <div class="grid grid-cols-7 gap-2">
                <mat-form-field class="col-span-3">
                    <mat-label>Alus 2</mat-label>
                    <app-select-vessel [formControl]="form.controls.vessel2Id" [allowNewVessel]="true" (createNewVessel)="createNewVessel()"/>
                    @if (form.controls.vessel2Id.value; as vesselId) {
                        <mat-hint align="end"><a href="#" (click)="openVesselInfoDialog($event, vesselId)">Aluksen tiedot</a></mat-hint>
                    }
                </mat-form-field>
            </div>
        </app-vertical-form>
    </app-common-dialog-form>
} @else {
    <mat-spinner class="default-spinner"/>
}
